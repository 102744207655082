.fl{
padding-top: 10px;

padding-bottom: 10px;
position: relative;
    text-align: center;
    overflow: auto;
    white-space: nowrap;

  >div{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    *{color: rgba(20, 20, 20, 0.767);}
    @media only screen and (max-width: 700px) {
        justify-content: start;
    }
  }

    div{
        img{
            vertical-align:middle;
            margin-bottom:10px;
        }
        height: 70px;

    

        border-radius: 10px;
        padding:6px;
    margin-right: 10px;
    margin-left: 10px;  
    -webkit-user-select: none;
    -khtml-user-select: none;
      -moz-user-select: none;
       -ms-user-select: none;
        -o-user-select: none;
           user-select: none;  
 
}


    .green{
        background-color: #b5e6743b;
  color: black;
//   box-shadow: 0 0 7px -4px;
  min-width: 80px;
  border:1px solid #b5e674;
  // filter: blur(0.1px);


    }
    .red{
        background-color: #f5998248;
  color: black;
//   box-shadow: 0 0 10px -4px;
  min-width: 80px;  
  // filter: blur(0.1px);
 border:1px solid #f59982;
}

  .small{
      font-size: 12px;
  }
}
