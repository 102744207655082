
.insta {
  padding: 30px;
  margin-top: 20px;
}

.titlemark {
  background: linear-gradient(to top, #cef5f993 50%, transparent 50%);

}

.main {

  .title,h2 {
    font-family: visionheavy;
    font-weight: 800;
    color: rgba(26, 32, 46, 0.829);
    font-size: 30px;
    text-align: center;
    margin-top: 50px;
  }

  background: #ECEDEF;
  padding:20px;

  .container {
    overflow: hidden;
    width: 60%;
    min-width: 600px;
    position: relative;
    left: 50%;
    background-color: white;
    box-shadow: 0 0 10px -10px;
    transform: translate(-50%);
    border-radius: 30px;

    .instr {
      padding: 20px;

      .off{
        padding:0 40px 0 50px;
        margin-bottom: 30px;
        font-size: 18px;
      }

      .card {
        width: 80%;
        // min-height: 100px;
        box-shadow: 0 2px 10px -5px;
        border-radius: 10px;
        position: relative;
        left: 50%;
        transform: translate(-50%);
        padding: 10px;
        padding-left: 30px;
        padding-right: 30px;
        font-size: 20px;
       margin-bottom: 35px;
       background-color: rgba(236, 237, 239, 0.589);

        .number {
          user-select: none;
          font-size: 25px;
          color: white;
          font-family: visionheavy;
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: absolute;
          background-color: #5352ed;
          height: 40px;
          width: 40px;
          border-radius: 25px;
          text-align: center;
          vertical-align: middle;
          top: -20px;
          left: 10px;
        }
      }
    }


    >img {
      position: relative;
      left: 50%;
      transform: translate(-50%);
    }

    .warning {
      text-decoration: dashed;
      font-size: 10px;
      font-style: oblique;
      padding-left: 40px;
      padding-right: 40px;
    }
  }


  @media only screen and (max-width: 700px) {
    padding: 10px;

    .container {
      min-width: auto;
      width: 98%;
      .card {
        width: 95% !important;
      }
    }

    .off{
      padding:0 10px 0 10px !important;
      margin-bottom: 30px;
      font-size: 16px !important;
    }
    h2{
      padding:0 10px 0 10px !important;

    }

   
  }
}

.of{
  color:grey;
  text-decoration: line-through;
  font-weight: 500;
  
}